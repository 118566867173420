<template>
  <c-box>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Asupan Energi / Energy (kkal/kcal)
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.energyIntake }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Kebutuhan Energi / Energy (kkal/kcal)
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.energyNeeds }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Presentase Kecukupan Energi / Energy (kkal/kcal)
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.energyAdequancy }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Asupan Protein sekarang (gram)
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.currentProteinIntake }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Kebutuhan Protein per Kg per Berat Badan
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.proteinNeeds }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Kebutuhan Asupan Protein
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.proteinIntakeNeeds }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Konstanta Kebutuhan Protein
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.proteinNeedsConstant }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        % Kecukupan Protein
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.proteinAdequancy }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Asupan Lemak / Fat (gram)
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.currentFatIntake }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Konstan Kebutuhan Lemak / Fat (gram)
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.fatNeedsConstant }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Kebutuhan Asupan Lemak / Fat (gram)
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.fatIntakeNeeds }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Presentase Kecukupan Lemak / Fat (gram)
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.fatAdequancy }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Asupan Karbohidrat / Carbohydrate (gram)
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.currentCarbohydrateIntake }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Kebutuhan Karbohidrat / Carbohydrate (gram)
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.carbohydrateIntakeNeeds }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Presentase Kecukupan Karbohidrat / Carbohydrate (gram)
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.carbohydrateAdequancy }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Asupan Serat / Fiber (gram)
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.fiberIntake }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Kebutuhan Serat / Fiber (gram)
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.fiberNeeds }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Presentase Kecukupan Serat / Fiber (gram)
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.fiberAdequancy }}
      </c-text>
    </c-form-control>
  </c-box>
</template>

<script>
export default {
  name: "CalorieMacroNutrientIntakeHistory",
  props: ["value"],
}
</script>
