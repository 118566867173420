<template>
  <c-box>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Jenis olahraga & durasi/Exercise type & duration
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.excercise }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Tipikal keaktifan pada hari kerja (weekdays)
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.weekdaysTypicalActivity }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Tipikal keaktifan pada hari libur dan akhir pekan
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.weekendTypicalActivity }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Presentase Aktivitas Fisik
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.activityPercentage }}
      </c-text>
    </c-form-control>
  </c-box>
</template>

<script>
export default {
  name: "PhysicalActivityHistory",
  props: ["value"],
}
</script>