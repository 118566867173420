<template>
  <c-box>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Problem
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.problem }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Etiology
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.etiology }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Sign & Symptoms
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.signSymptom }}
      </c-text>
    </c-form-control>
  </c-box>
</template>

<script>
export default {
  name: "PrimaryNutritionDiagnosisHistory",
  props: ["value"],
}
</script>