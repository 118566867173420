<template>
  <c-box>
    <!-- <c-flex align="center" justify="center">
      <c-button
        variant-color="blue"
        variant="link"
        :isDisabled="!item.previousId"
        @click="onGetAdimeNotes(item.previousId)"
      >
        <c-image
          w="24px"
          h="24px"
          object-fit="cover"
          :src="require('@/assets/icon-chevron-left-filled.svg')"
        />
      </c-button>
      <c-heading as="h3" size="lg" mx="50px">
        Tanggal {{ getFormatDate(item.createdAt) }}
      </c-heading>
      <c-button
        variant-color="blue"
        variant="link"
        :isDisabled="!item.nextId"
        @click="onGetAdimeNotes(item.nextId)"
      >
        <c-image
          w="24px"
          h="24px"
          object-fit="cover"
          :src="require('@/assets/icon-chevron-right-filled.svg')"
        />
      </c-button>
    </c-flex> -->
    <c-box
      border-width="1px"
      border-color="brand.900"
      rounded="lg"
      :px="['20px', '45px']"
      :py="['30px', '50px']"
    >
      <template v-for="(step, index) in steps">
        <CardStep
          v-if="stepIndex === index"
          :key="index"
          :current="index + 1"
          :total="steps.length"
          :is-prev="index !== 0"
          :is-next="index < steps.length - 1"
          @prev="stepIndex--"
          @next="stepIndex++"
        >
          <c-text font-weight="700" mb="42px"> {{ step.title }} </c-text>
          <ProfileForm v-if="step.type === 'profile'" v-model="item.profile" />
          <NutritionAssessment
            v-if="step.type === 'nutritionAssessment'"
            v-model="item.nutritionAssessment"
          />
          <PhysicalActivityHistory
            v-if="step.type === 'physicalActivityHistory'"
            v-model="item.physicalActivityHistory"
          />
          <CalorieMacroNutrientIntake
            v-if="step.type === 'calorieMacroNutrientIntake'"
            v-model="item.calorieMacroNutrientIntake"
          />
          <FoodNutritionHistory
            v-if="step.type === 'foodNutritionHistory'"
            v-model="item.foodNutritionHistory"
          />
          <BodyCompositionMeasure
            v-if="step.type === 'bodyCompositionMeasure'"
            v-model="item.bodyCompositionMeasure"
          />
          <BiochemicalData
            v-if="step.type === 'biochemicalData'"
            v-model="item.biochemicalData"
          />
          <PrimaryNutritionDiagnosis
            v-if="step.type === 'primaryNutritionDiagnosis'"
            v-model="item.primaryNutritionDiagnosis"
          />
          <NutritionIntervention
            v-if="step.type === 'nutritionIntervention'"
            v-model="item.nutritionIntervention"
          />
          <NutritionMonitoring
            v-if="step.type === 'nutritionMonitoring'"
            v-model="item.nutritionMonitoring"
          />
        </CardStep>
      </template>
    </c-box>
  </c-box>
</template>

<script>
import CardStep from "@/views/nutritionists/adime-note/card-step"
import StepData from "../steps.js"
import ProfileForm from "./profile.vue"
import NutritionAssessment from "./nutrition-assessment.vue"
import PhysicalActivityHistory from "./physical-activity-history.vue"
import CalorieMacroNutrientIntake from "./calorie-macro-nutrient-intake.vue"
import FoodNutritionHistory from "./food-nutrition-history.vue"
import BodyCompositionMeasure from "./body-composition-measure.vue"
import BiochemicalData from "./biochemical-data.vue"
import PrimaryNutritionDiagnosis from "./primary-nutrition-diagnosis.vue"
import NutritionIntervention from "./nutrition-intervention.vue"
import NutritionMonitoring from "./nutrition-monitoring.vue"
import moment from "moment"
import _ from "lodash"
import { mapActions, mapState } from "vuex"

export default {
  name: "AdimeNoteHistory",
  components: {
    CardStep,
    ProfileForm,
    NutritionAssessment,
    PhysicalActivityHistory,
    CalorieMacroNutrientIntake,
    FoodNutritionHistory,
    BodyCompositionMeasure,
    BiochemicalData,
    PrimaryNutritionDiagnosis,
    NutritionIntervention,
    NutritionMonitoring,
  },
  data() {
    return {
      stepIndex: 0,
      steps: StepData.steps,
    }
  },
  computed: {
    ...mapState({
      item: (s) => s.nutriAdimeNote.adimeNote,
    }),
    id() {
      return this.$route.query?.id
    },
    isEmptyItem() {
      return _.isEmpty(this.item)
    },
  },
  watch: {
    item: {
      immediate: true,
      handler(val) {
        if (val) {
          if (_.isEmpty(val.profile)) {
            val.profile = {
              firstName: "",
              lastName: "",
              middleName: "",
              age: 0,
              referralReason: "",
              mealPlanObjective: "",
            }
          }
          if (_.isEmpty(val.nutritionAssessment)) {
            val.nutritionAssessment = {
              weight: 0,
              height: 0,
              bodyMassIndex: 0,
              nutritionalStatus: null,
              basalEnergyNeeds: null,
              maternalCondition: null,
              maternalCalorieAddition: null,
              maintain: null,
              improve: null,
            }
          }
          if (_.isEmpty(val.physicalActivityHistory)) {
            val.physicalActivityHistory = {
              excercise: null,
              weekdaysTypicalActivity: "",
              weekendTypicalActivity: "",
              activityPercentage: 0,
            }
          }
          if (_.isEmpty(val.calorieMacroNutrientIntake)) {
            val.calorieMacroNutrientIntake = {
              energyIntake: 0,
              energyNeeds: 0,
              energyAdequancy: 0,
              currentProteinIntake: 0,
              proteinNeeds: 0,
              proteinIntakeNeeds: 0,
              proteinNeedsConstant: 0,
              proteinAdequancy: 0,
              currentFatIntake: 0,
              fatNeedsConstant: 0,
              fatIntakeNeeds: 0,
              fatAdequancy: 0,
              currentCarbohydrateIntake: 0,
              carbohydrateIntakeNeeds: 0,
              carbohydrateAdequancy: 0,
              fiberIntake: 0,
              fiberNeeds: 0,
              fiberAdequancy: 0,
            }
          }
          if (_.isEmpty(val.foodNutritionHistory)) {
            val.foodNutritionHistory = {
              foodPreference: "",
              foodAllergies: "",
              fluidConsumption: "",
              personServeFood: "",
              additionalNote: "",
            }
          }
          if (_.isEmpty(val.bodyCompositionMeasure)) {
            val.bodyCompositionMeasure = {
              waistSize: "",
              waistSizeStandard: "",
              waistSizeConclusion: "",
              fat: "",
              fatStandard: "",
              fatConclusion: "",
              visceralFat: "",
              visceralFatStandard: "",
              visceralFatConclusion: "",
              muscleMass: "",
              muscleMassStandard: "",
              muscleMassConclusion: "",
            }
          }
          if (_.isEmpty(val.biochemicalData)) {
            val.biochemicalData = {
              bloodSugar: 0,
              bloodSugarStandard: 0,
              bloodSugarConclusion: "",
              colestrol: 0,
              colestrolStandard: 0,
              colestrolConclusion: "",
              colestrolHDL: 0,
              colestrolHDLStandard: 0,
              colestrolHDLConclusion: "",
              colestrolLDL: 0,
              colestrolLDLStandard: 0,
              colestrolLDLConclusion: "",
              uricAcid: 0,
              uricAcidStandard: 0,
              uricAcidConclusion: "",
              nutritionFocusedPhysicalFinding: "",
              clientHistory: "",
              others: [
                {
                  field: "",
                  value: "",
                },
              ],
            }
          }
          if (_.isEmpty(val.primaryNutritionDiagnosis)) {
            val.primaryNutritionDiagnosis = {
              problem: "",
              etiology: "",
              signSymptom: "",
            }
          }
          if (_.isEmpty(val.nutritionIntervention)) {
            val.nutritionIntervention = {
              nutritionPrescription: "",
              goal: "",
            }
          }
          if (_.isEmpty(val.nutritionMonitoring)) {
            val.nutritionMonitoring = {
              checkParts: [],
              indicators: [],
            }
          }
        }
      },
    },
    $route: {
      immediate: true,
      handler() {
        this.getAdimeNotesById(this.id)
      },
    },
  },
  methods: {
    ...mapActions({
      getAdimeNotesById: "nutriAdimeNote/getAdimeNotesById",
    }),
    getFormatDate(date) {
      moment.locale("id")
      return moment(date).format("D MMMM yyyy")
    },
    onGetAdimeNotes(id) {
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, id: id },
      })
    },
  },
}
</script>