<template>
  <c-box>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Berat Badan/Weight (kg)
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.weight }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Tinggi Badan/Height (cm)
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.height }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        IMT/BMI (kg/m2):
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.bodyMassIndex }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Status Gizi
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.nutritionalStatus }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Kebutuhan Energi Bassal (kkal)
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.basalEnergyNeeds }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Kondisi Keibuan
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.maternalCondition }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Tambahan kalori kondisi keibuan
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.maternalCalorieAddition }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Pertahankan Hal Ini / What to Maintain
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.maintain }}
      </c-text>
    </c-form-control>
    <c-form-control mb="18px">
      <c-text
        mb="5px"
        color="brand.900"
        font-weight="500"
        font-size="16px"
        line-height="24px"
      >
        Perbaiki Hal InI / What to Improve
      </c-text>
      <c-text color="superDarkGray.900" font-size="18px" line-height="27px">
        {{ value.improve }}
      </c-text>
    </c-form-control>
  </c-box>
</template>

<script>
export default {
  name: "NutritionAssessmentForm",
  props: ["value"],
}
</script>